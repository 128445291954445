import "../CascadeStyleSheet/carousel.css"
import React, { useEffect, useState } from 'react';
import '../CascadeStyleSheet/slider.css';
export default function Carousel_()
{
    const images = [
        "https://i.ibb.co/48V8pV6/et1.jpg",
        "https://i.ibb.co/sCfCv9Q/et2.jpg",
        "https://i.ibb.co/z6zLRNd/et3.jpg",
        "https://i.ibb.co/17wvgpR/et4.jpg"
    ]
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);
    const closePopup = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;
    
    return (

    <div>
     
      <div id="myPopin" className="popin"> 
        <div className="popin-content">
        <span className="close" onClick={closePopup}>&times;</span>
            <div id="myCarousel" className="carousel slide" data-ride="carousel">

            <div className="slider">
            <div
                className="slider-images"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div className="slide" key={index}>
                        <img src={image} alt={`Slide ${index}`} width="100%"  height="100%" />
                    </div>
                ))}
            </div>
        </div>
      </div>
     </div> 
    </div>
    </div>
    )
}