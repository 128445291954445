import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import FileUpload from './FileUpload';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import {Select , MenuItem} from '@mui/material'
import { Snackbar, Alert ,CircularProgress, Box } from '@mui/material';
import apiController from '../Controllers/api_controller';


export default function FormComponent() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ height: '', Weight: '' });
  const [error, setError] = useState({ height: false, Weight: false });
  const [selectedOption, setSelectedOption] = useState('');
  const [MBT,setMBT]=useState(false)
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [FBT,setFBT]=useState(false)
  const [selectedOption1, setSelectedOption1] = useState('');
  const [response,setresponse]=useState({"data":{},"status":400})
    const handleChangeSelect = (event) => {
        setSelectedOption1(event.target.value);
    };
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const handleFileChange = (file) => {
        setFile(file);
    };
   

    const handleFileChange1 = (file1) => {
        console.log(file1)
        setFile1(file1);
    };
    console.log(file)
    console.log(file1)
    const Generate_male_measurements = async (e) => {
      e.preventDefault()
      setLoading(true)
      setSnackbarOpen(true);
      let gender = 2
      if(FBT === true){
        gender = 0  
      }
      if(MBT === true) {
         gender =1 
      }
      console.log(formData)
      const params = {
        height:formData.height,
        weight:formData.Weight,
        gender:gender,
        front_image:file,
        side_image:file1


      }
      const r = await apiController.GetMeasurments(params.height,params.weight,params.front_image,params.side_image,params.gender)
      console.log(r)
      
      setresponse(
      {
        "data":JSON.parse(r.data.substring(1)),
        "status":r.status
      })

         
          }

    console.log(response)
    console.log(response.status)
    if(response.status === 200)
      {
       navigate("/results",{state:response})
      }
      
      const Generate_female_measurements = async (e) => {
        e.preventDefault()
        setLoading(true)
        setSnackbarOpen(true);
        let gender = 2
        if(FBT === true){
          gender = 0  
        }
        if(MBT === true) {
           gender =1 
        }
        console.log(formData)
        const params = {
          height:formData.height,
          weight:formData.Weight,
          gender:gender,
          front_image:file,
          side_image:file1
  
  
        }
        const r = await apiController.GetMeasurments(params.height,params.weight,params.front_image,params.side_image,params.gender)
        console.log(r)
        
        setresponse(
        {
          "data":JSON.parse(r.data.substring(1)),
          "status":r.status
        })
  
           
            }
  
      console.log(response)
      console.log(response.status)
      if(response.status === 200)
        {
         navigate("/results",{state:response})
        }
  
  const handleOptionChange = (event) => {
    console.log(event.target.value)
    setSelectedOption(event.target.value);
    if(event.target.value==="male"){
      setMBT(true)
      setFBT(false)
    }
    else{
      setFBT(true)
      console.log(FBT)
      setMBT(false)
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData state for either height or Weight
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Reset error state for the field being edited
    setError((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    const newError = { height: !formData.height, Weight: !formData.Weight };
    
    if (newError.height || newError.Weight) {
      setError(newError); // Set errors if any field is empty
      return;
    }

    console.log('Form submitted:', formData); // Handle submission here
    setFormData({ height: '', Weight: '' }); // Reset form fields after submission
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '20px', maxWeight: '400px', margin: '0 auto' }}>
      <TextField
        label="Height"
        variant="outlined"
        fullWeight
         size="small"
        name="height" // This name matches the state key
        value={formData.height}
        onChange={handleChange} // Calls handleChange to update state
        error={error.height} // Displays error if height is empty
        helperText={error.height ? 'Height is required' : ''}
        style={{ marginBottom: '16px'  ,width:"500px",backgroundColor:"white"}}
      />
      <br></br><br></br>
      <TextField
        label="Weight"
        variant="outlined"
        fullWeight
         size="small"
        name="Weight" // This name matches the state key
        value={formData.Weight}
        onChange={handleChange} // Calls handleChange to update state
        error={error.Weight} // Displays error if Weight is empty
        helperText={error.Weight ? 'Weight is required' : ''}
        style={{ marginBottom: '16px' , width:"500px",backgroundColor:"white"}}
      />
      <br></br><br></br>
            <FileUpload onFileChange={handleFileChange}  onFileChange1= {handleFileChange1}  ></FileUpload>
      
      
      <br></br><br></br>
      <FormControl component="fieldset">
      <FormLabel component="legend" style={{color:"white"}} >Select Your Gender:</FormLabel>
      <RadioGroup value={selectedOption} onChange={handleOptionChange}>
        <FormControlLabel value="male" style={{color:"white"}} control={<Radio />} label="Male" />
        <FormControlLabel value="female" style={{color:"white"}} control={<Radio />} label="Female" />
      </RadioGroup>
      
      <div>
      {MBT === false  && FBT === false ? (<div> </div> )  : 
       MBT === true  && FBT === false ? (<div>
        <span style={{color:"white"}}>Male Morphology</span>
       <Select
           labelId="select-label"
           value={selectedOption1}
           onChange={handleChangeSelect}
           label="Choose an option"
       >
           <MenuItem value="">
               <em>None</em>
           </MenuItem>
           <MenuItem value="ectomorph">H / Ectomorph</MenuItem>
           <MenuItem value="mesomorph">V / Mesomorph</MenuItem>
           <MenuItem value="endomorph">O / Endomorph</MenuItem>
       </Select>
       <Snackbar open={snackbarOpen} autoHideDuration={10000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={loading ? 'info' : 'error'}>
          {loading ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={20} color="inherit" />
              <span style={{ marginLeft: 8 }}>Loading...</span>
            </Box>
          ) : error ? (
            `Error: ${error}`
          ) : (
            'Data fetched successfully!'
          )}
        </Alert>
      </Snackbar>
       <br></br><br></br>
       <Button variant="contained" onClick={Generate_male_measurements}>Generate Male Measures</Button>
       </div>
      ) : (<div style={{color:"white"}}>
        <span style={{color:"white"}}>Female Morphology</span>
        <Select
           labelId="select-label"
           value={selectedOption1}
           onChange={handleChangeSelect}
           label="Choose an option"
        >
           <MenuItem value="">
               <em>None</em>
           </MenuItem>
           <MenuItem value="A / Triangle / Pear">A / Triangle / Pear</MenuItem>
           <MenuItem value="V / InvertedTriangle">V / InvertedTriangle</MenuItem>
           <MenuItem value="H / Rectangle /Column">H / Rectangle /Column</MenuItem>
           <MenuItem value="X / Hourglass">X / Hourglass</MenuItem>
          
           <MenuItem value="B / Diamond">B /Diamond</MenuItem>
           <MenuItem value="O / Rounded / Apple ">O / Rounded / Apple </MenuItem>
          </Select>
           <Snackbar open={snackbarOpen} autoHideDuration={10000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={loading ? 'info' : 'error'}>
          {loading ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={20} color="inherit" />
              <span style={{ marginLeft: 8 }}>Loading...</span>
            </Box>
          ) : error ? (
            `Error: ${error}`
          ) : (
            'Data fetched successfully!'
          )}
        </Alert>
      </Snackbar>
         <br></br><br></br>
        <Button variant="contained" onClick={Generate_female_measurements}>Generate Female Measures</Button>
       </div>
      )}
      </div>
    </FormControl>
    <br></br><br></br>
      
    </form>
  );
}
