// src/PhotoSlider.js
import React, { useEffect, useState } from 'react';
import '../CascadeStyleSheet/slider.css'; // For styling

const images = [
    'https://miro.medium.com/v2/resize:fit:499/1*DGb2WVDZI1ZUXlZvUsGExw.png',
    'https://i.ibb.co/mXvCdGT/female.jpg',
    'https://i.ibb.co/r37dmPv/male.jpg',
];

function PhotoSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    return (
        <div className="slider">
            <div
                className="slider-images"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div className="slide" key={index}>
                        <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PhotoSlider;
