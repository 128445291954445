
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Content from './Components/Content';
import WpContent from './Components/withoutphotos';
import Results from './Components/results';

function App() {
  return (
    <div>
     
  
     <Router>
     
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/index" element={<Content />} />
        <Route path="/withoutPhotos" element={<WpContent />} />
        <Route path="/results" element = {<Results/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
