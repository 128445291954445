

import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';


const NavBar = () => {
    return (
        <AppBar position="static" style={{backgroundColor : "#041d2e" }}>
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 ,backgroundColor : "#041d2e" }}>
                 
                    <img src="https://i.ibb.co/K7k0D56/logo-weefizz.png" width="250px" height="50px"></img>
                </Typography>

               
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
